import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog35.jpg'
import styles from './post-grid.module.css'

const MilkThistle = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jaké účinky má ostropestřec? '}
        description={
          'Ostropestřec – nejen pro zdravá játra!“ Často ze všech stran slýcháme, jaké zázračné a jedinečné účinky Ostropestřec má. Je tomu opravdu tak?'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/mikl-thistle">
          Jaké účinky má ostropestřec? 
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Jaké účinky má ostropestřec? " date="12.09.23" />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <h5 className={styles.postTitle}>
                Ostropestřec, je opravdu tak zázračný?
              </h5>
              <p className={styles.postText}>
                „Ostropestřec – nejen pro zdravá játra!“ Často ze všech stran
                slýcháme, jaké zázračné a jedinečné účinky Ostropestřec má. Je
                tomu opravdu tak?
              </p>

              <p className={styles.postText}>
                Ostropestřec mariánský (lat. Silybum marianum), lidově Kristova
                koruna či bejlí panny Marie, je
                <b> bylina využívaná v léčitelství </b>již ve staré Antice. Tam
                se využíval jako lék na hadí uštknutí. Od 17. století ho můžeme
                znát jako léčivku spojenou s
                <b>
                  {' '}
                  uzdravováním různých jaterních komplikací, problémů s krevním
                  tlakem a dutinou břišní či depresí.{' '}
                </b>
                Jedná se o rostlinu ze Středomoří, ale nalezneme ji také v Malé
                Asii, v jihozápadní Asii nebo na Kanárských ostrovech. Dnes už
                se vyskytuje na všech kontinentech.
              </p>
              <h5 className={styles.postTitle}>K čemu je ostropestřec?</h5>
              <p className={styles.postText}>
                Jako i mnoho jiných bylin se ostropestřec
                <b> používá dodnes, </b>a to například k tlumení projevů různých
                onemocnění. Ostropestřec je známý tím, že léčivé složky této
                byliny<b> napomáhají regeneraci jaterních buněk, </b>působí jako
                <b> antioxidanty a posilují imunitní systém. </b>To však nejsou
                všechny blahodárné účinky, které nám může nabídnout, mezi další
                patří např.: pomoc při poruchách trávení a bolestech hlavy,
                tlumení zánětů a alergií, snižování horečky a zevně je možné ho
                používat na hemeroidy, bércové vředy nebo křečové žíly a mnoho
                dalších. Používá se také u onkologických pacientů, kde je
                nápomocen především při zvládání nežádoucích účinků
                chemoterapeutik.
              </p>

              <h5 className={styles.postTitle}>
                Detoxikuje ostropestřec náš organismus?
              </h5>
              <p className={styles.postText}>
                Ne, náš organismus je tak dokonalý, že to<b> zvládne sám, </b>jak
                už jsme si ostatně vysvětlili v{' '}
                <a href="https://nutritionpro.cz/blog/post-2/" target="_blank">
                  tomto článku
                </a>
                . Pokud budete mít<b> špatně nastavený jídelníček </b>a nebudete
                dodržovat zásady zdravého životního stylu, ostropestřec vám s
                detoxikací nepomůže. V případě nadměrného zatěžování jater vám
                sice může pomoci<b> regenerovat jaterní buňky, </b>pokud však
                nezměníte životní styl, tak vás nevyléčí ani ostropestřec, ani
                jiný zázračný lék.
              </p>

              <p className={styles.postText}>
                <b>TIP</b>: <i>S nastavením správného jídelníčku pomohou </i>{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  <i>naše krabičky</i>
                </a>
              </p>
              <h5 className={styles.postTitle}>
                Na co si při užívání ostropestřce dát pozor
              </h5>

              <p className={styles.postText}>
                Užívání ostropestřce může mít i nežádoucí účinky, a to například
                laxativní účinek, může vyvolat žaludeční obtíže nebo mírné
                alergické reakce.{' '}
              </p>

              <p className={styles.postText}>
                Pokud se rozhodnete pro suplementaci této byliny, poraďte se
                raději se svým lékařem. Látky v ní obsažené mohou totiž
                <b> negativně korelovat </b> s vaším zdravotním stavem či vašimi
                léky.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default MilkThistle
